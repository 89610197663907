<template>
    <a-modal
        title="详情"
        width='50%'
        :visible="visible"
        :destroyOnClose="true"
        @cancel="handleCancel"
        :footer="null"
    >
        <a-spin :spinning="confirmLoading">
            <a-row>
                <a-form layout="inline">
                    <a-col :md="24" :sm="24">
                        <a-form-item  label="举报原因">
                            <span>{{details.reason}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="24" :sm="24">
                        <a-form-item  label="举报内容">
                            <span>
                                {{details.content}}
                            </span>
                        </a-form-item>
                    </a-col>
					<a-col :md="24" :sm="24" v-if="details.images">
					    <a-form-item  label="举报图片">
					        <div style="display: flex;">
								<viewer v-for="(item, index) in details.images.split(',')" :key="index">
									<img :src="item" style="width: 60px; height: 60px;margin: 0 10px 10px 0"/>
								</viewer>
							</div>
					    </a-form-item>
					</a-col>
                    <a-col :md="24" :sm="24">
                        <a-form-item  label="审核状态">
                            <span :style="{color:details.checkResult==1?'orange':details.checkResult==2?'green':details.checkResult==3?'已拒绝':'red'}">
                                {{details.checkResult==1?'待处理':details.checkResult==2?'已通过':details.checkResult==3?'已拒绝':''}}
                            </span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="24" :sm="24" v-if="details.checkResult==2 || details.checkResult==3">
                        <a-form-item  label="审核备注">
                            <span>{{details.dealExplain}}</span>
                        </a-form-item>
                    </a-col>
                    <a-col :md="24" :sm="24">
                        <a-form-item  label="举报时间">
                            <span>{{details.createTime}}</span>
                        </a-form-item>
                    </a-col>
                </a-form>
            </a-row>
        </a-spin>
    </a-modal>
</template>

<script>
import { 
  reportPageList,
  reportAudit,
  reportDetail,
  reportDelete
} from '@/api/modular/mallLiving/report'

export default {
    data(){
        return {
            visible: false, //modal框显示状态
            confirmLoading: false,
            previewVisible: false,
            previewVisible1: false,
            id:'',
            details:{},
            form: this.$form.createForm(this),
        }
    },
    methods:{
        //初始化方法
        detail(record){
            this.visible = true
            this.confirmLoading = true
            //获取详情数据
            setTimeout(()=>{
                reportDetail({id: record.id}).then((res)=>{
                    if(res.success){
                        this.details = res.data
                        this.confirmLoading = false
                    }
                }).finally((res)=>{
                    setTimeout(()=>{
                        this.confirmLoading = false
                    },5000)
                })
            },100)
            
        },
        // 身份证正面大图显示与隐藏
        preVisible(){
            this.previewVisible = true;
        },
        Cancelpreview(){
            this.previewVisible = false;
        },
        // 身份证反面大图显示与隐藏
        preVisible1(){
            this.previewVisible1 = true;
        },
        Cancelpreview1(){
            this.previewVisible1 = false;
        },
        // 点击遮罩层或右上角叉或取消按钮的操作
        handleCancel(){
            this.visible = false
            this.confirmLoading = false
            setTimeout(()=>{
                this.details = {} //关闭之后清空
            },100)
        }
    }
}
</script>
<style lang="less" scoped>
    .mytable{
        margin-bottom:70px;
        border-collapse:collapse;
        width:100% ;
        height:250px;
        .title {
            background: rgb(207, 248, 248);
            width:20%
        }
        td{
            border:2px solid rgb(228, 225, 225);
            padding:7px;
            font-size:15px;
            width:30%;
        }
    }
    img{
        width:80px;
        height: 50px;
    }
</style>